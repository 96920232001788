import * as echarts from 'echarts';
import { mapGetters } from 'vuex';
import moment from 'moment';
import Loading from '@/components/Loading/Loading.vue';
import { useConvertCode } from '@/api/convert_code';
import { getDashboardData } from '@/api/dashboard';
import AIFlowButton from '@/components/Button/Button.vue';
import CopyRight from '@/components/CopyRight/CopyRight.vue';
import AIFlowEmpty from '@/components/Empty/Empty.vue';
import { toFixed } from '@/utils/util';

export default {
	name: 'Dashboard',

	data() {
		const yesterday = moment().subtract(1, 'days').toDate();
		const endTime = moment().add(3, 'hours').toDate();

		return {
			timeRange: [yesterday, endTime],
			granularity: 'hours',
			username: '',
			secretkey: '',
			redemptionCodeDialog: false,
			searching: false,
			banlance: '0',
			callCount: '0',
			totalAmount: '0',
			modelUsageList: [],
			time_model_usage: {},
			codeFormData: {
				code: ''
			},
			rules: {
				code: [
          { validator: this.validateCode, trigger: 'change' }
        ],
			},
			codeUsing: false,
			granularityOptions: [
				{
					label: 'Hours',
					value: 'hours'
				},

				{
					label: 'Day',
					value: 'day'
				}
			]
		};
	},

	computed: {
    ...mapGetters(['isAdmin']),
  },

	methods: {
		async init() {
			try {
				await this.getDashboardData();
				this.createPieChart();
				this.createModelDistribution();
			}
			catch(err) {}
		},

		async refreshDashboard() {
			if (!this.timeRange) {
				this.$message({
          message: 'Time range is required',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
        return;
			}

			try {
				await this.getDashboardData();
				this.refreshDistribution();
				this.refreshPieChart();
			}
			catch(err) {}
		},

		async getDashboardData() {
			const startTime = moment(this.timeRange[0]).utc().valueOf();
			const endTime = moment(this.timeRange[1]).utc().valueOf();

			if (this.searching) {
				return;
			}

			this.searching = true;

			try {
				const res = await getDashboardData({
					start_time: startTime,
					end_time: endTime,
					granularity: this.granularity,
					user_name: this.username,
					secret_key: this.secretkey
				});

				this.renderBanlance({
					banlanceValue: res.data.banlance
				});
				this.renderCallcount({
					...res.data.model_usage
				});
				this.totalAmount = toFixed(res.data.model_usage.amount, 3);
				this.modelUsageList = res.data.model_usage.model_usage_count_detail || [];
				this.time_model_usage = res.data.model_usage.time_model_usage;
			}
			catch(err) {
				this.$message({
          message: err.message || 'query fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
			}

			this.searching = false;
		},

		renderBanlance({ banlanceValue }) {
			this.banlance = toFixed(banlanceValue, 2);
		},

		renderCallcount({model_usage_total_count}) {
			this.callCount = model_usage_total_count;
		},

		useCode() {
			this.codeFormData.code = '';
			this.redemptionCodeDialog = true;
		},

		validateCode(rule, value, callback) {
			if (!value) {
        return callback(new Error('code is required'));
      }

      callback();
		},

		checkForm() {
      return new Promise((resolve) => {
        this.$refs.keyForm.validate((valid) => {
          resolve(valid);
        });
      });
    },

		async codeSubmit() {
			if (this.codeUsing) {
        return;
      }

      const checkResult = await this.checkForm();

      if (!checkResult) {
        this.$message({
          message: 'Form data is invilidate',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
        return;
      }

      this.codeUsing = true;

      try {
        await useConvertCode({
          code: this.codeFormData.code,
        });

        this.$message({
          message: 'Success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.redemptionCodeDialog = false;
        this.refreshDashboard();
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'use code fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.codeUsing = false;
		},

		refreshDistribution() {
			const { dataFrame, times } = this.time_model_usage;
			const localTimes = times.map((time) => {
				const utcTime = moment.utc(time);

      	return utcTime.local().format('YYYY-MM-DD HH:mm');
			});

			this.distributionChart.clear();
			this.distributionChart.setOption({
				tooltip: {
					backgroundColor: '#252837',
					borderColor: 'rgba(255, 255, 255, .1)',  // 边框颜色
    			borderWidth: 1,  
			    trigger: 'axis',  // 触发类型，轴触发
			    axisPointer: {    // 坐标轴指示器配置
			      type: 'shadow'  // 默认为直线，可选为：'line' | 'shadow'
			    },
			    textStyle: {
			      color: '#fff', // 设置文字颜色
			      fontSize: 14   // 设置文字大小（可选）
			      // 其他文本样式配置，如字体、行高等
			    },
			    // 你可以自定义tooltip的内容，否则会自动生成
			    formatter: function (params) {
			    	let total = 0;

			    	params.forEach(function (item) {
			      	total += item.value;
			      });

			    	let result = `合计: $${toFixed(total, 4)}<br/>${params[0].axisValue}<br/>`;

			      params.forEach(function (item) {
			      	total += item.value;
			      	if (item.value <= 0) {
			      		return;
			      	}
			        result += item.marker + item.seriesName + ': $' + toFixed(item.value, 6) + '<br/>';
			      });
			      return result;
			    }
			  },
			  grid: {
			    top: '10px',    // 调整图表距离容器顶部的距离
			    left: 0,    // 调整图表距离容器左边的距离
			    right: 0,   // 调整图表距离容器右边的距离
			    bottom: 0,  // 调整图表距离容器底部的距离
			    containLabel: true  // 使得刻度标签不溢出
			  },
				series: dataFrame,
				xAxis: {
			    type: 'category',
			    data: localTimes,
			  },
			  yAxis: {
			    type: 'value',
			    splitLine: { // 设置 X 轴网格线
            show: true,
            lineStyle: {
              color: '#3c3e4a', // 这里设置网格线颜色
              type: 'solid' // 可以是 'solid' 或者 'dashed'
            }
	        }
			  },
			});
		},

		createModelDistribution() {
			const { dataFrame, times } = this.time_model_usage;
			const localTimes = times.map((time) => {
				const utcTime = moment.utc(time);

      	return utcTime.local().format('YYYY-MM-DD HH:mm');
			});
			const chartDom = this.$el.querySelector('.model_distribution_chart');

			this.distributionChart = echarts.init(chartDom);
			this.distributionChart.setOption({
				tooltip: {
					backgroundColor: '#252837',
					borderColor: 'rgba(255, 255, 255, .1)',  // 边框颜色
    			borderWidth: 1,  
			    trigger: 'axis',  // 触发类型，轴触发
			    axisPointer: {    // 坐标轴指示器配置
			      type: 'shadow'  // 默认为直线，可选为：'line' | 'shadow'
			    },
			    textStyle: {
			      color: '#fff', // 设置文字颜色
			      fontSize: 14   // 设置文字大小（可选）
			      // 其他文本样式配置，如字体、行高等
			    },
			    // 你可以自定义tooltip的内容，否则会自动生成
			    formatter: function (params) {
			    	let total = 0;

			    	params.forEach(function (item) {
			      	total += item.value;
			      });

			    	let result = `合计: $${toFixed(total, 4)}<br/>${params[0].axisValue}<br/>`;

			      params.forEach(function (item) {
			      	total += item.value;
			      	if (item.value <= 0) {
			      		return;
			      	}
			        result += item.marker + item.seriesName + ': $' + toFixed(item.value, 6) + '<br/>';
			      });
			      return result;
			    }
			  },
			  grid: {
			    top: '10px',    // 调整图表距离容器顶部的距离
			    left: 0,    // 调整图表距离容器左边的距离
			    right: 0,   // 调整图表距离容器右边的距离
			    bottom: 0,  // 调整图表距离容器底部的距离
			    containLabel: true  // 使得刻度标签不溢出
			  },
				xAxis: {
			    type: 'category',
			    data: localTimes,
			  },
			  yAxis: {
			    type: 'value',
			    splitLine: { // 设置 X 轴网格线
            show: true,
            lineStyle: {
              color: '#3c3e4a', // 这里设置网格线颜色
              type: 'solid' // 可以是 'solid' 或者 'dashed'
            }
	        }
			  },
			  series: dataFrame
			});
		},

		refreshPieChart() {
			const newDataList = this.modelUsageList.map((data) => {
				return {
					value: data.value,
					name: data.name,
					itemStyle: { color: data.color, shadowBlur: 200, shadowColor: 'rgba(0, 0, 0, 0.5)' }
				};
			});

			// this.pieChart.clear();
			this.pieChart.setOption({
				backgroundColor: '#252837',
			  title: {
			    text: 'Model Pie',
			    left: 'center',
			    top: 20,
			    textStyle: {
			      color: '#fff'
			    }
			  },
			  tooltip: {
			    trigger: 'item',
			    backgroundColor: '#252837',
					borderColor: 'rgba(255, 255, 255, .1)',  // 边框颜色
    			borderWidth: 1,  
			    axisPointer: {    // 坐标轴指示器配置
			      type: 'shadow'  // 默认为直线，可选为：'line' | 'shadow'
			    },
			    textStyle: {
			      color: '#fff', // 设置文字颜色
			      fontSize: 14   // 设置文字大小（可选）
			      // 其他文本样式配置，如字体、行高等
			    },
			  },
			  visualMap: {
			    show: false,
			    min: 80,
			    max: 600,
			    inRange: {
			      colorLightness: [0, 1]
			    }
			  },
				series: [
			    {
			      name: 'Access From',
			      type: 'pie',
			      radius: '55%',
			      center: ['50%', '50%'],
			      data: newDataList.sort(function (a, b) {
			        return a.value - b.value;
			      }),
			      roseType: 'radius',
			      label: {
			        color: 'rgba(255, 255, 255, 0.3)'
			      },
			      labelLine: {
			        lineStyle: {
			          color: 'rgba(255, 255, 255, 0.3)'
			        },
			        smooth: 0.2,
			        length: 10,
			        length2: 20
			      },
			      animationType: 'scale',
			      animationEasing: 'elasticOut',
			      animationDelay: function (idx) {
			        return Math.random() * 200;
			      }
			    }
			  ]
			});
		},

		createPieChart() {
			const newDataList = this.modelUsageList.map((data) => {
				return {
					value: data.value,
					name: data.name,
					itemStyle: { color: data.color, shadowBlur: 200, shadowColor: 'rgba(0, 0, 0, 0.5)' }
				};
			});

			const chartDom = this.$el.querySelector('.pie__box');

			this.pieChart = echarts.init(chartDom);
			this.pieChart.setOption({
				backgroundColor: '#252837',
			  title: {
			    text: 'Model Pie',
			    left: 'center',
			    top: 20,
			    textStyle: {
			      color: '#fff'
			    }
			  },
			  tooltip: {
			    trigger: 'item',
			    backgroundColor: '#252837',
					borderColor: 'rgba(255, 255, 255, .1)',  // 边框颜色
    			borderWidth: 1,  
			    axisPointer: {    // 坐标轴指示器配置
			      type: 'shadow'  // 默认为直线，可选为：'line' | 'shadow'
			    },
			    textStyle: {
			      color: '#fff', // 设置文字颜色
			      fontSize: 14   // 设置文字大小（可选）
			      // 其他文本样式配置，如字体、行高等
			    },
			  },
			  visualMap: {
			    show: false,
			    min: 80,
			    max: 600,
			    inRange: {
			      colorLightness: [0, 1]
			    }
			  },
			  series: [
			    {
			      name: 'Access From',
			      type: 'pie',
			      radius: '55%',
			      center: ['50%', '50%'],
			      data: newDataList.sort(function (a, b) {
			        return a.value - b.value;
			      }),
			      roseType: 'radius',
			      label: {
			        color: 'rgba(255, 255, 255, 0.3)'
			      },
			      labelLine: {
			        lineStyle: {
			          color: 'rgba(255, 255, 255, 0.3)'
			        },
			        smooth: 0.2,
			        length: 10,
			        length2: 20
			      },
			      animationType: 'scale',
			      animationEasing: 'elasticOut',
			      animationDelay: function (idx) {
			        return Math.random() * 200;
			      }
			    }
			  ]
			});
		}
	},

	mounted() {
		this.init();
	},

	components: {
		Loading,
		AIFlowButton,
		AIFlowEmpty,
		CopyRight
	}
}