import AIFlowButton from '@/components/Button/Button.vue';
import CopyRight from '@/components/CopyRight/CopyRight.vue';
import { sinup, login } from '@/api/login';
import { queryUrl } from '@/utils/util';

export default {
  name: 'Login',

  components: {
    AIFlowButton,
    CopyRight
  },

  data() {
    return {
      sinupformData: {
        username: '',
        password: '',
        passwordAgain: '',
      },
      sininformData: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          { validator: this.validateUserName, trigger: 'change' }
        ],
        password: [
          { validator: this.validateUserNamePass, trigger: 'change' }
        ],
        passwordAgain: [
          { validator: this.validateUserNamePassAgain, trigger: 'change' }
        ]
      },
      isLogin: true,
      submitting: false
    };
  },

  methods: {
    goSignup() {
      this.isLogin = false;
      this.sinupformData.username = '';
      this.sinupformData.password = '';
      this.sinupformData.username = '';
      this.sinupformData.passwordAgain = '';
      setTimeout(()=>{
        this.$refs.signupForm.clearValidate();
      }, 0);
    },

    goSignin() {
      this.isLogin = true;
      this.sininformData.username = '';
      this.sininformData.password = '';
      setTimeout(()=>{
        this.$refs.signinForm.clearValidate();
      }, 0);
    },

    checkForm({ formName }) {
      return new Promise((resolve) => {
        this.$refs[formName].validate((valid) => {
          resolve(valid);
        });
      });
    },

    async sinup() {
      const checkResult = await this.checkForm({
        formName: 'signupForm'
      });

      if (!checkResult) {
        this.$message({
          message: 'The form data is invalid',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
        return;
      }

      this.submitting = true;

      try {
        const res = await sinup({
          username: this.sinupformData.username,
          password: this.sinupformData.password
        });

        this.$message({
          message: 'Congratulations, the registration is successful',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.goSignin();
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Registration failed',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.submitting = false;
    },

    async sinin() {
      const checkResult = await this.checkForm({
        formName: 'signinForm'
      });

      if (!checkResult) {
        this.$message({
          message: 'The form data is invalid',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
        return;
      }

      this.submitting = true;

      const { redirect_url } = queryUrl(window.location.href);

      try {
        const res = await login({
          username: this.sininformData.username,
          password: this.sininformData.password
        });

        this.$message({
          message: 'Congratulations, the login is successful',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });

        if (redirect_url) {
          window.location.href = decodeURIComponent(redirect_url);
          return;
        }

        await this.$store.dispatch('updateUserInfo');
        this.$router.push({
          path: '/home'
        });
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Login failed',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.submitting = false;
    },

    validateUserName(rule, value, callback) {
      if (!value) {
        return callback(new Error(`It can't be empty`));
      }

      if (value.length < 6) {
        return callback(new Error('The username must be at least 6 characters long'));
      }

      if (!/^[A-Za-z0-9]+$/.test(value)) {
        return callback(new Error('Must be uppercase and lowercase letters or numbers'));
      }

      callback();
    },

    validateUserNamePass(rule, value, callback) {
      if (!value) {
        return callback(new Error(`It can't be empty`));
      }

      if (/\s/.test(value)) {
        return callback(new Error('Spaces cannot be included'));
      }

      if (value.length < 8) {
        return callback(new Error('The password must be at least 8 characters long'));
      }

      callback();
    },

    validateUserNamePassAgain(rule, value, callback) {
      if (!value) {
        return callback(new Error(`It can't be empty`));
      }

      if (value !== this.sinupformData.password) {
        return callback(new Error('Passwords are inconsistent'));
      }

      callback();
    }
  },
}