import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
	isLogin: false,
	userName: '',
	type: 0
}

export default {
	state,
	getters,
	actions,
	mutations
};