import { network } from './network';

export async function saveSetting(opts) {
	const { callback_url, balance } = opts;
	const res = await network({
		url: '/api/update_setting',
		method: 'post',
		params: {
			callback_url,
			balance
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function getSetting() {
	const res = await network({
		url: '/api/get_setting',
		method: 'post',
		params: {}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}