import dayjs from 'dayjs';
import { queryModels } from '@/api/model';
import Loading from '@/components/Loading/Loading.vue';
import AIFlowButton from '@/components/Button/Button.vue';
import AIFlowEmpty from '@/components/Empty/Empty.vue';
import CopyRight from '@/components/CopyRight/CopyRight.vue';

export default {
	name: 'Model',

	data() {
		return {
      pageLoading: true,
      pageSize: 100,
      total: 1,
      currentPage: 1,
			tableData: [],
      logSrcDataList: {},
		};
	},

	methods: {
		init() {
      this.initFirstPage();
    },

    initFirstPage() {
      this.total = 1;
      this.loadPage(1);
    },

    pageChange() {
      this.loadPage(this.currentPage);
    },

    getComputeType(type) {
    	if (type === 0) {
    		return '-';
    	}

    	return '+';
    },

    refresh() {
    	this.loadPage(this.currentPage);
    },

    async loadPage(pageNumber) {
      this.pageLoading = true;

      try {
        const res = await queryModels();
        
        if (res.code == 0) {
          this.logSrcDataList = res.data.list;
          this.tableData = this.makeRenderList(this.logSrcDataList);
          this.total = res.data.total_count;
        } else {
          this.$message({
            message: 'query fail',
            type: 'error',
            showClose: true,
            customClass: 'custom-message',
          });
        }
      }
      catch(err) {
      	console.log(err);
        this.$message({
          message: 'query fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.pageLoading = false;
    },

    hasModel(list, name) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].model_name === name) {
          return true;
        }
      }

      return false;
    },

    makeRenderList(srcList) {
      const result = [];

    	srcList.forEach((model) => {
        if (!this.hasModel(result, model.model_name) && model.model_name !== 'dall-e-3') {
          result.push({
            model_name: model.model_name,
            input_price: model.input_price,
            output_price: model.output_price,
          });
        }
    	});

      return result;
    },

    getTypeName(type) {
    	if (type === 0) {
    		return 'Consumption';
    	}

    	return 'Unkown';
    },

    transTime(time) {
      return dayjs(time).locale('zh-cn').format('YYYY-MM-DD HH:mm');
    },
	},

	mounted() {
		this.init();
	},

	components: {
		Loading,
    AIFlowButton,
    AIFlowEmpty,
    CopyRight
	}
};