import { network } from './network';

// 登录
export async function login(params) {
	const { username, password } = params;
	const res = await network({
		url: '/api/signin',
		method: 'post',
		params: {
			username,
			password,
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

// 注册
export async function sinup(params) {
	const { username, password } = params;
	const res = await network({
		url: '/api/signup',
		method: 'post',
		params: {
			username,
			password,
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function logout() {
	const res = await network({
		url: '/api/login_out',
		method: 'post',
		params: {}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function changePassword(params) {
	const { password, password_again } = params;
	const res = await network({
		url: '/api/change_password',
		method: 'post',
		params: {
			password_again,
			password,
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}