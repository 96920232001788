import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { getLogs } from '@/api/log';
import { mixins } from '@/mixins/index';
import Loading from '@/components/Loading/Loading.vue';
import AIFlowButton from '@/components/Button/Button.vue';
import AIFlowEmpty from '@/components/Empty/Empty.vue';
import CopyRight from '@/components/CopyRight/CopyRight.vue';

export default {
	name: 'Log',

  mixins: [mixins],

	data() {
		return {
      pageLoading: true,
      pageSize: 100,
      total: 1,
      currentPage: 1,
			tableData: [],
      logSrcDataList: {},
		};
	},

  computed: {
    ...mapGetters(['isAdmin']),
  },

	methods: {
		init() {
      this.initFirstPage();
    },

    initFirstPage() {
      this.total = 1;
      this.loadPage(1);
    },

    pageChange() {
      this.loadPage(this.currentPage);
    },

    getComputeType(type) {
    	if (type === 0) {
    		return '-';
    	}

    	return '+';
    },

    refresh() {
    	this.loadPage(this.currentPage);
    },

    async loadPage(pageNumber) {
      this.pageLoading = true;

      try {
        const res = await getLogs({
          page_number: pageNumber,
          page_size: this.pageSize
        });
        
        if (res.code == 0) {
          this.logSrcDataList = res.data.list;
          this.tableData = this.makeRenderList(this.logSrcDataList);
          this.total = res.data.total_count;
        } else {
          this.$message({
            message: 'query fail',
            type: 'error',
            showClose: true,
            customClass: 'custom-message',
          });
        }
      }
      catch(err) {
        this.$message({
          message: 'query fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.pageLoading = false;
    },

    makeRenderList(srcList) {
    	return srcList.map((log) => {
    		return {
    			created_at: log.created_at,
    			channel_id: log.channel_id,
          data_type: log.data_type,
    			user_name: log.user_detail && log.user_detail.user_name,
    			secretkey_name: log.secretkey_detail && log.secretkey_detail.name,
    			type: log.type,
    			modle_name: log.model_detail && log.model_detail.model_name,
    			time: log.time,
    			prompt: log.prompt_tokens,
    			completion: log.completion_tokens,
    			used: log.used,
    			retry: log.retry_channel_ids,
    			detail: log.compute_detail
    		};
    	});
    },

    getTypeName(type) {
    	if (type === 0) {
    		return 'Expenditure';
    	}

      if (type === 1) {
        return 'Recharge';
      }

    	return 'Unkown';
    },
	},

	mounted() {
		this.init();
	},

	components: {
		Loading,
    AIFlowButton,
    AIFlowEmpty,
    CopyRight
	}
};