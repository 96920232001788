import { render, staticRenderFns } from "./Setting.vue?vue&type=template&id=47442f1e&scoped=true"
import script from "./setting.js?vue&type=script&lang=js&external"
export * from "./setting.js?vue&type=script&lang=js&external"
import style0 from "./setting.scss?vue&type=style&index=0&id=47442f1e&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47442f1e",
  null
  
)

export default component.exports