var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"alflow-btn",class:{
		'alflow-btn--primary': _vm.type === 'primary',
		'alflow-btn--danger': _vm.type === 'danger',
		'alflow-btn--loading': _vm.loading,
		'alflow-btn--medium': _vm.size === 'medium',
		'alflow-btn--small': _vm.size === 'small',
		'alflow-btn--mini': _vm.size === 'mini',
		'is-plain': _vm.plain
	},on:{"click":_vm.clickBtn}},[_c('div',{staticClass:"alflow-btn__body"},[_vm._t("default")],2),(_vm.loading)?_c('div',{staticClass:"alflow-btn__loading"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }