import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/pages/Home/Home.vue';
import Login from '@/pages/Login/Login.vue';
import NotFound from '@/pages/404/404.vue';
import Dashboard from '@/pages/Dashboard/Dashboard.vue';
import Bussiness from '@/pages/Bussiness/Bussiness.vue';
import SecretKey from '@/pages/SecretKey/SecretKey.vue';
import Model from '@/pages/Model/Model.vue';
import Log from '@/pages/Log/Log.vue';
import Setting from '@/pages/Setting/Setting.vue';
import Docs from '@/pages/Docs/Docs.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home/dashboard'
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/home/dashboard',
    component: Home,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard
      },
      {
        path: 'bussiness',
        name: 'bussiness',
        component: Bussiness
      },
      {
        path: 'secret_key',
        name: 'secret_key',
        component: SecretKey
      },
      {
        path: 'billing',
        name: 'billing',
        component: Log
      },
      {
        path: 'model',
        name: 'model',
        component: Model
      },
      {
        path: 'setting',
        name: 'setting',
        component: Setting
      },
      // {
      //   path: 'docs',
      //   name: 'docs',
      //   component: Docs
      // }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/404',
    name: 'notFoundPage',
    component: NotFound,
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound
  },
];

const router = new VueRouter({
  base: '/desktop',
  routes,
  mode: 'history'
});

// 登录态控制路由规则
router.beforeEach((to, from, next) => {
  const isLogin = store.getters.isLogin;
  const isManager = store.getters.isAdmin;

  // 如果直接访问的是登录页，并且没有登录，则渲染登录页面
  if (/\/login/.test(to.fullPath) && !isLogin) {
    next();
    return;
  }

  // 如果直接访问的是登录页，已登录，则重定向到聊天页面
  if (/\/login/.test(to.fullPath) && isLogin) {
    next('/home');
    return;
  }

  // 除此之外没有登录，全部重定向到登录页去
  if (!isLogin) {
    next(`/login?redirect_url=${window.location.href}`);
    return;
  }

  if (to.fullPath === '/') {
    next('/home');
    return;
  }

  if (/\/home\/bussiness/.test(to.fullPath) && !isManager) {
    next({
      path: '/404'
    });
    return;
  }

  next();
})

export default router;
