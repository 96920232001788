import { network } from './network';

export async function addChannel(params) {
	const res = await network({
		url: '/api/add_channel',
		method: 'post',
		params: {
			...params
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function getChannel(opts) {
  const res = await network({
    url: '/api/get_channel',
    method: 'get',
    params: {
      page: opts.page_number,
      page_size: opts.page_size
    }
  });

  if (res.code !== 0) {
		return Promise.reject(res);
	}

  return res;
}

export async function delChannel(opts) {
  const res = await network({
    url: '/api/del_channel',
    method: 'post',
    params: {
      channel_id: opts.channel_id,
    }
  });

  if (res.code !== 0) {
		return Promise.reject(res);
	}

  return res;
}

export async function testChannel(opts) {
  const res = await network({
    url: '/api/test_channel',
    method: 'post',
    params: {
      channel_id: opts.channel_id,
    }
  });

  if (res.code !== 0) {
		return Promise.reject(res);
	}

  return res;
}

export async function updateChannel(opts) {
  const res = await network({
    url: '/api/update_channel',
    method: 'post',
    params: {
      ...opts
    }
  });

  if (res.code !== 0) {
		return Promise.reject(res);
	}

  return res;
}