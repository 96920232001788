import { mapGetters } from 'vuex';
import CopyRight from '@/components/CopyRight/CopyRight.vue';
import {
  dashbordIcon,
  bussinessIcon,
  secretKeyIcon,
  modelIcon,
  logIcon,
  orderIcon,
  settingIcon,
  usersIcon,
  docsIcon
} from '@/svg';

export default {
  name: 'Home',

  components: {
    CopyRight
  },

  data() {
    return {
      activeMenuId: 'dashboard',
      menuList: []
    };
  },

  computed: {
    ...mapGetters(['isAdmin']),
  },

  created() {
    const { name } = this.$route;

    this.activeMenuId = name;
    this.createMenu();
  },

  methods: {
    checkMenu(item) {
      this.activeMenuId = item.id;
      this.$router.push({
        path: `/home/${item.id}`
      });
    },

    createMenu() {
      if (this.isAdmin) {
        this.menuList = [
          {
            id: 'dashboard',
            name: 'Dashboard',
            icon: dashbordIcon
          },

          {
            id: 'bussiness',
            name: 'Channel',
            icon: bussinessIcon
          },

          {
            id: 'secret_key',
            name: 'SecretKey',
            icon: secretKeyIcon
          },

          {
            id: 'model',
            name: 'Model',
            icon: modelIcon
          },

          {
            id: 'billing',
            name: 'Billing',
            icon: logIcon
          },

          // {
          //   id: 'docs',
          //   name: 'Docs',
          //   icon: docsIcon
          // },

          {
            id: 'setting',
            name: 'Setting',
            icon: settingIcon
          }
        ]
      }
      else {
        this.menuList = [
          {
            id: 'dashboard',
            name: 'Dashboard',
            icon: dashbordIcon
          },

          {
            id: 'secret_key',
            name: 'SecretKey',
            icon: secretKeyIcon
          },

          {
            id: 'model',
            name: 'Model',
            icon: modelIcon
          },

          {
            id: 'billing',
            name: 'Billing',
            icon: logIcon
          },

          // {
          //   id: 'docs',
          //   name: 'Docs',
          //   icon: docsIcon
          // },

          {
            id: 'setting',
            name: 'Setting',
            icon: settingIcon
          }
        ]
      }
    }
  },

  watch: {
    $route(to, from) {
      this.activeMenuId = to.name;
    }
  }
}

