import { sleep } from '@/utils/util';
import { getUserInfo } from '@/api/userInfo';
import { logout } from '@/api/login';

const actions = {
	async updateUserInfo({ commit, state, rootState }, payload) {
		try {
			const res = await getUserInfo();

			if (res.code === 0) {
				commit('UPDATE_USER_INFO', {
					isLogin: true,
					userName: res.data.userName,
					type: res.data.type
				});
				return;
			}

			commit('UPDATE_USER_INFO', {
				isLogin: false,
				userName: '',
				type: 0
			});
		}
		catch(err) {
			commit('UPDATE_USER_INFO', {
				isLogin: false,
				userName: '',
				type: 0
			});
		}
	},

	updateSetting({ commit, state, rootState }, payload) {
		commit('UPDATE_SETTING', {
			...payload
		});
	},

	updatePlan({ commit, state, rootState }, payload) {
		commit('UPDATE_PLAN', {
			...payload,
		});
	},

	async loginOut({ commit, state, rootState }) {
		await logout();
		commit('UPDATE_USER_INFO', {
			isLogin: false,
			userName: '',
			type: 0
		});
	},

	async clearLoginStatus({ commit, state, rootState }) {
		commit('UPDATE_USER_INFO', {
			isLogin: false,
			userName: '',
			type: 0
		});
	}
}

export default actions;
