import { network } from './network';

export async function createSecretkey(opts) {
	const { name } = opts;
	const res = await network({
		url: '/api/add_secretkey',
		method: 'post',
		params: {
			name,
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function deleteSecretkey(opts) {
	const { key_id } = opts;
	const res = await network({
		url: '/api/delete_secretkey',
		method: 'post',
		params: {
			key_id,
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function querySecretkey(opts) {
  const res = await network({
    url: '/api/get_secretkey',
    method: 'get',
    params: {
      page: opts.page_number,
      page_size: opts.page_size
    }
  });

  if (res.code !== 0) {
		return Promise.reject(res);
	}

  return res;
}

export async function updateSecretkey(opts) {
  const res = await network({
    url: '/api/update_secretkey',
    method: 'post',
    params: {
      ...opts
    }
  });

  if (res.code !== 0) {
		return Promise.reject(res);
	}

  return res;
}