import { sendLoadingIcon } from '@/svg';

export default {
	name: 'Loading',

	props: {
		width: {
			type: Number,
			default: 30
		},

		height: {
			type: Number,
			default: 30
		}
	},

	data() {
		return {
			sendLoadingIcon
		}
	}
}