import { network } from './network';

export async function getUserInfo() {
	const res = await network({
		url: '/api/user_info',
		method: 'get',
		params: {}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function saveSettings(params) {
	const res = await network({
		url: '/api/save_settings',
		method: 'post',
		params
	});

	return res;
}