import { network } from './network';

export async function useConvertCode(params) {
	const { code } = params;
	const res = await network({
		url: '/api/use_convert_code',
		method: 'post',
		params: {
			code
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}