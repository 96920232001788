const getters = {
	isLogin(state, getters) {
		return state.isLogin;
	},

	userType(state, getters) {
		return state.userType;
	},

	openId(state, getters) {
		return state.openId;
	},

	nickname(state) {
		return state.nickname;
	},

	photo(state) {
		return state.photo;
	},

	isAdmin(state) {
		return state.type === 1;
	},

	setting(state) {
		return state.setting;
	},

	vipLevel(state) {
		return state.vipLevel;
	},

	vipExpireDate(state) {
		return state.vipExpireDate;
	},

	vipRulesInfo(state) {
		return state.vipRulesInfo;
	},
};

export default getters