import Loading from '@/components/Loading/Loading.vue';
import AIFlowButton from '@/components/Button/Button.vue';
import CopyRight from '@/components/CopyRight/CopyRight.vue';
import AIFlowEmpty from '@/components/Empty/Empty.vue';
import { createSecretkey, querySecretkey, updateSecretkey, deleteSecretkey } from '@/api/secretkey.js';
import { mixins } from '@/mixins/index';

export default {
	name: 'Secretkey',

  mixins: [mixins],

	data() {
		return {
      addSecretkeyDialogVisible: false,
      delSecretkeyDialogVisible: false,
      editSecretkeyDialogVisible: false,
      editSecretkeyLoading: false,
      delLoading: false,
      pageLoading: false,
      pageSize: 10,
      total: 1,
      currentPage: 1,
			tableData: [],
      secretkeySrcDataList: [],
      disableLoading: false,
      submitting: false,
      keyFormData: {
        name: ''
      },
      rules: {
        name: [
          { validator: this.validateKeyName, trigger: 'change' }
        ],
      },
      isEdit: false
		};
	},

  methods: {
    init() {
      this.initFirstPage();
    },

    initFirstPage() {
      this.total = 1;
      this.loadPage(1);
    },

    pageChange() {
      this.loadPage(this.currentPage);
    },

    edit(keyInfo) {
      this.isEdit = true;
      this.addSecretkeyDialogVisible = true;
      this.keyFormData.name = keyInfo.name;
      this.currentEditKey = keyInfo;
    },

    readyDelKey(keyInfo) {
      this.delSecretkeyDialogVisible = true;
      this.delSecretkeyData = keyInfo;
    },

    async delSecretkeyOk() {
      if (this.delLoading) {
        return;
      }

      this.delLoading = true;

      try {
        await deleteSecretkey({
          key_id: this.delSecretkeyData.key_id
        });

        this.$message({
          message: 'Delete success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.delSecretkeyDialogVisible = false;
        this.loadPage(this.currentPage);
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Delete fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.delLoading = false;
    },

    async loadPage(pageNumber) {
      this.pageLoading = true;

      try {
        const res = await querySecretkey({
          page_number: pageNumber,
          page_size: this.pageSize
        });
        
        if (res.code == 0) {
          this.secretkeySrcDataList = res.data.list;
          this.tableData = this.makeKeyList(this.secretkeySrcDataList);
          this.total = res.data.total_count;
        } else {
          this.$message({
            message: '查询失败',
            type: 'error',
            showClose: true,
            customClass: 'custom-message',
          });
        }
      }
      catch(err) {
        this.$message({
          message: '查询失败',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.pageLoading = false;
    },

    statusFormat(status) {
      if (status === 0) {
        return 'Disabled';
      }

      if (status === 1) {
        return 'Active';
      }

      return 'Unknown';
    },

    makeKeyList(srcDataList) {
      return srcDataList.map((srcData) => {
        const { key_id, name, value, status, used, created_at } = srcData;
        const hideKey = `${value.slice(0, 8)}****${value.slice(value.length-5, value.length)}`

        return {
          key_id,
          key_value: hideKey,
          name,
          status,
          used,
          created_at,
          disableLoading: false
        };
      });
    },

    copy(keyInfo) {
      const { key_id } = keyInfo;
      const textarea = document.createElement('textarea');
      const keyFullValue = this.secretkeySrcDataList.filter((keySrcInfo) => {
        return keySrcInfo.key_id === key_id;
      });
      
      textarea.value = keyFullValue[0].value;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      textarea.remove();
      this.$message({
        message: 'Copied',
        type: 'success',
        showClose: true,
        customClass: 'custom-message',
      });
    },

    validateKeyName(rule, value, callback) {
      if (!value) {
        return callback(new Error('name is required'));
      }

      callback();
    },

    checkForm() {
      return new Promise((resolve) => {
        this.$refs.keyForm.validate((valid) => {
          resolve(valid);
        });
      });
    },

    cancelCreateKey() {
      this.addSecretkeyDialogVisible = false;
      this.keyFormData.name = '';
    },

    openAddDialog() {
      this.addSecretkeyDialogVisible = true;
      this.isEdit = false;
      this.keyFormData.name = '';
      setTimeout(()=>{
        this.$refs.keyForm.clearValidate();
      }, 0);
    },

    submit() {
      if (this.isEdit) {
        this.editKey();
      }
      else {
        this.createKey()
      }
    },

    async updateStatus(keyInfo) {
      if (keyInfo.disableLoading) {
        return;
      }

      keyInfo.disableLoading = true;

      try {
        await updateSecretkey({
          key_id: keyInfo.key_id,
          status: keyInfo.status === 1 ? 0 : 1,
        });

        this.$message({
          message: 'Operate success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.loadPage(this.currentPage);
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Operate fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      keyInfo.disableLoading = false;
    },

    async editKey() {
      if (this.submitting) {
        return;
      }

      const checkResult = await this.checkForm();

      if (!checkResult) {
        this.$message({
          message: 'Form data is invilidate',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
        return;
      }

      this.submitting = true;

      try {
        await updateSecretkey({
          name: this.keyFormData.name,
          key_id: this.currentEditKey.key_id,
        });

        this.$message({
          message: 'Create secretkey success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.addSecretkeyDialogVisible = false;
        this.loadPage(this.currentPage);
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Create secretkey fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.submitting = false;
    },

    async createKey() {
      if (this.submitting) {
        return;
      }

      const checkResult = await this.checkForm();

      if (!checkResult) {
        this.$message({
          message: 'Form data is invilidate',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
        return;
      }

      this.submitting = true;

      try {
        await createSecretkey({
          name: this.keyFormData.name
        });

        this.$message({
          message: 'Create secretkey success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.addSecretkeyDialogVisible = false;
        this.loadPage(this.currentPage);
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Create secretkey fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.submitting = false;
    },
  },

  mounted() {
    this.init();
  },

	components: {
		Loading,
    AIFlowButton,
    AIFlowEmpty,
    CopyRight
	}
}