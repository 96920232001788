import Loading from '@/components/Loading/Loading.vue';
import AIFlowButton from '@/components/Button/Button.vue';
import CopyRight from '@/components/CopyRight/CopyRight.vue';
import { eventBus } from '@/utils/event';
import { saveSetting, getSetting } from '@/api/setting';
import { changePassword, logout } from '@/api/login';

export default {
	name: 'Setting',

	data() {
		return {
			formData: {
				callback_url: '',
				balance: ''
			},
			loading: true,
			submitting: false,
			resetPasswordLoading: false,
			changePasswordDialogVisible: false,
			logoutLoading: false,
			changePasswordForm: {
				password: '',
				passwordAgain: ''
			},
			changePasswordRules: {
				password: [
          { validator: this.validatePassword, trigger: 'change' }
        ],
        passwordAgain: [
          { validator: this.validatePasswordAgain, trigger: 'change' }
        ]
			},
			rules: {
				callback_url: [
          { validator: this.validateCallbackUrl, trigger: 'change' },
        ],

        balance: [
        	{ validator: this.validateBalance, trigger: 'change' },
        ]
			}
		};
	},

	methods: {
		init() {
			this.setFormInitialData();
		},

		async setFormInitialData() {
			try {
				const res = await getSetting();
				const { balance, callback_url } = res.data;

				this.formData.callback_url = callback_url;
				this.formData.balance = balance;
			}
			catch(err) {
				this.$message({
          message: err.message ? err.message : 'query setting info fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
			}

			this.loading = false;
		},

		checkForm() {
      return new Promise((resolve) => {
        this.$refs['settingForm'].validate((valid) => {
          resolve(valid);
        });
      });
    },

    checkPasswordForm() {
      return new Promise((resolve) => {
        this.$refs['passwordForm'].validate((valid) => {
          resolve(valid);
        });
      });
    },

    validatePassword(rule, value, callback) {
      if (!value) {
        return callback(new Error('Password is required'));
      }

      if (/\s/.test(value)) {
        return callback(new Error('Password cannot contain empty characters'));
      }

      if (value.length < 8) {
        return callback(new Error('The password length cannot be less than 8 characters'));
      }

      callback();
    },

    validatePasswordAgain(rule, value, callback) {
      if (!value) {
        return callback(new Error('Password is required'));
      }

      if (value !== this.changePasswordForm.password) {
        return callback(new Error('The passwords entered twice do not match'));
      }

      callback();
    },

    onChangePasswordSubmit() {
    	this.changePasswordDialogVisible = true;
    	this.changePasswordForm.password = '';
    	this.changePasswordForm.passwordAgain = '';
    },

    async sendChangePasswordRequest() {
    	if (this.resetPasswordLoading) {
    		return;
    	}

    	const validateRes = await this.checkPasswordForm();

			if (!validateRes) {
				this.$message({
          message: 'Form data is invilidate, please check.',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
        return;
			}

			this.resetPasswordLoading = true;

			try {
        const res = await changePassword({
          password: this.changePasswordForm.password,
          passwordAgain: this.changePasswordForm.passwordAgain
        });

        this.$message({
          message: 'Change success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Change fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

			this.resetPasswordLoading = false;
			this.changePasswordDialogVisible = false;
    	this.changePasswordForm.password = '';
    	this.changePasswordForm.passwordAgain = '';
    },

    async Logout() {
    	this.logoutLoading = true;

			try {
        const res = await logout();

        this.$message({
          message: 'Logout success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        eventBus.$emit('relogin');
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Logout fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

			this.logoutLoading = false;
    },

		async onSubmit() {
			const validateRes = await this.checkForm();

			if (!validateRes) {
				this.$message({
          message: 'Form data is invilidate, please check.',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
        return;
			}

			this.submitting = true;

			try {
        const res = await saveSetting({
          callback_url: this.formData.callback_url,
          balance: this.formData.balance
        });

        this.$message({
          message: 'Save success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Save fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

			this.submitting = false;
		},

		validateCallbackUrl(rule, value, callback) {
			const urlPattern = /^(https?:\/\/)?([\w\-]+(\.[\w\-]+)+)(:[0-9]{1,5})?(\/[\w\-.\~\:\/\?\#\[\]@!$&'()*+,;=]*)?$/i;

			if (value === '') {
				return callback();
			}

      if (!urlPattern.test(value)) {
        return callback(new Error('The format of the URL is not valid.'));
      }

      callback();
    },

    validateBalance(rule, value, callback) {
    	const numberRegex = /^-?\d+(\.\d+)?$/;

    	if (value === '') {
				return callback();
			}

			if (!numberRegex.test(value)) {
				return callback(new Error('Must be a number.'));
			}

			callback();
    }
	},

	mounted() {
		this.init();
	},

	components: {
		Loading,
    AIFlowButton,
    CopyRight
	}
};