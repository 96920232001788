import { eventBus } from '@/utils/event';
import { mapGetters } from 'vuex';
import AIFlowButton from '@/components/Button/Button.vue';
import { getPushMessages } from '@/api/app';
import { queryParams, sleep } from '@/utils/util';

export default {
  name: 'App',

  data() {
    return {
      forceUpdateVisible: false,
      messageList: []
    };
  },

  computed: {
    ...mapGetters(['version', 'isLogin']),
    showMenu() {
      const blackList = ['prize'];

      return this.isLogin && !blackList.includes(this.$route.name);
    }
  },

  methods: {
    updateApp() {
      window.location.reload(true);
    },
  },

  mounted() {
    eventBus.$off('updateApp');
    eventBus.$on('updateApp', () => {
      console.log(1);
      this.forceUpdateVisible = true;
    });
    eventBus.$off('relogin');
    eventBus.$on('relogin', () => {
      this.$store.dispatch('clearLoginStatus');
      this.$router.push({
        path: '/login',
        query: {
          redirect_url: window.location.href
        }
      });
    });
  },

  components: {
    AIFlowButton
  }
}