import { network } from './network';

export async function getLogs(opts) {
	const res = await network({
    url: '/api/log_query',
    method: 'post',
    params: {
      page: opts.page_number,
      page_size: opts.page_size
    }
  });

  if (res.code !== 0) {
		return Promise.reject(res);
	}

  return res;
}