import { emptyIcon } from '@/svg';

export default {
	name: 'Empty',

	props: {
		content: {
			type: String,
			default: 'No Data'
		}
	},

	data() {
		return {
			emptyIcon
		};
	}
}