import { network } from './network';

export async function queryBusinessModels(params) {
	const { business } = params;
	const res = await network({
		url: '/api/query_business_model',
		method: 'post',
		params: {
			business,
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function queryModels() {
	const res = await network({
		url: '/api/query_model',
		method: 'post',
		params: {}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}