export default {
	name: 'Button',

	props: {
		type: {
			type: String,
			default: 'primary'
		},

		plain: {
			type: Boolean,
			default: false
		},

		size: {
			type: String,
			default: ''
		},

		loading: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {};
	},

	methods: {
		clickBtn() {
			this.$emit('click');
		}
	}
};