import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=2462cb74&scoped=true"
import script from "./home.js?vue&type=script&lang=js&external"
export * from "./home.js?vue&type=script&lang=js&external"
import style0 from "./home.scss?vue&type=style&index=0&id=2462cb74&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2462cb74",
  null
  
)

export default component.exports