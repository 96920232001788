import { render, staticRenderFns } from "./SecretKey.vue?vue&type=template&id=227c33aa&scoped=true"
import script from "./secretkey.js?vue&type=script&lang=js&external"
export * from "./secretkey.js?vue&type=script&lang=js&external"
import style0 from "./secretkey.scss?vue&type=style&index=0&id=227c33aa&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "227c33aa",
  null
  
)

export default component.exports